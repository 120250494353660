import { css } from '@emotion/react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import HTMLReactParser from 'html-react-parser'
import { ComponentPropsWithoutRef, Fragment } from 'react'

import { LogoStacked } from '@/features/logo'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'
import { getStateByName } from '@/utils/statesList'

const Footer = ({
  ...props
}: ComponentPropsWithoutRef<'footer'>): JSX.Element => {
  const { datoCmsMetadataModel: metadata } =
    useStaticQuery<Queries.FooterQuery>(graphql`
      query Footer {
        datoCmsMetadataModel {
          addresses {
            streetAddress
            city
            state
            zip
          }
          phone
          email
        }
      }
    `)
  const styles = {
    footer: css`
      position: relative;
      z-index: 1;
      display: grid;
      justify-items: center;
      padding: var(--row-72) var(--margin);
      background: ${colors.tealDark};
      color: #fff;
      font-size: var(--fs-15);
    `,
    logo: css`
      font-size: var(--fs-108);
      svg {
        height: 0.9em;
        display: flex;
      }
    `,
    addresses: css`
      margin-top: 3em;
      text-align: center;
      span {
        display: inline-block;
      }
    `,
    divider: css`
      display: inline-block;
      opacity: 0.333;
      ${mq().s} {
        display: none;
      }
    `,
    link: css`
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      margin: 0.25em 0;
      display: inline-block;
      @media (hover: hover) {
        &:hover {
          color: ${colors.tealLight};
        }
      }
      ${mq().s} {
        width: 100%;
      }
    `,
    contact: css`
      margin-top: 1em;
      text-align: center;
    `,
    contactHeading: css`
      display: inline-block;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: ${colors.tealLight};
      margin-right: 1em;
      ${mq().s} {
        width: 100%;
        margin: 1em 0 0.5em;
      }
    `,
  }
  return (
    <footer
      css={styles.footer}
      {...props}
    >
      <Link
        to="/"
        css={styles.logo}
      >
        <LogoStacked fill={'#fff'} />
      </Link>
      <div css={styles.addresses}>
        {metadata?.addresses?.map((address, i, array) => {
          const searchParams = new URLSearchParams({
            api: '1',
            query: [
              address?.streetAddress,
              address?.city,
              address?.state,
              address?.zip,
            ]
              .filter(val => val?.length)
              .join(', '),
          })
          return (
            <Fragment key={i}>
              <a
                css={styles.link}
                href={`https://www.google.com/maps/search/?${searchParams.toString()}`}
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  {HTMLReactParser(address?.streetAddress || '', {
                    trim: true,
                  })}
                  ,
                </span>{' '}
                <span>
                  <span>{address?.city},</span>{' '}
                  {getStateByName(address?.state || '')} {address?.zip}
                </span>
              </a>
              {i !== array.length - 1 && (
                <div css={styles.divider}>&emsp;|&emsp;</div>
              )}
            </Fragment>
          )
        })}
      </div>
      <div css={styles.contact}>
        <span css={styles.contactHeading}>Contact Us:</span>
        {metadata?.phone && (
          <a
            css={styles.link}
            href={`tel:${metadata?.phone}`}
            target="_blank"
            rel="noreferrer"
          >
            {metadata?.phone}
          </a>
        )}
        {metadata?.phone && metadata.email && (
          <div css={styles.divider}>&emsp;|&emsp;</div>
        )}
        {metadata?.email && (
          <a
            css={styles.link}
            href={`mailto:${metadata?.email}`}
            target="_blank"
            rel="noreferrer"
          >
            {metadata?.email}
          </a>
        )}
      </div>
    </footer>
  )
}
export default Footer
